import React, {useState} from 'react'
import { Button } from '../../../components/common/Button/Button'
import styles from './Student.module.scss'
import arrowShort from '../../../images/landing/arrowShort.svg'
import StudInf from '../../../images/landing/StudInf.svg'
import StudGrad from '../../../images/landing/StudGrad.svg'
import StudPred from '../../../images/landing/StudPred.svg'
import StuInt from '../../../images/landing/StuInt.svg'
import StudGit from '../../../images/landing/StudGit.svg'
import ContactUs from '../ContactUs/ContactUs'
import Accepted from '../Accepted/Accepted';
import { motion } from 'framer-motion';
function Student({request}) {
  const [select, setSelect] = useState('information')
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupAccepted, setShowPopupAccepted] = useState(false);
  const textAnimation = {
    hidden: {
      // y: 0,
      // x: -50,
      opacity: 0,
    },
    visible: (custom) => ({
      x: 0,
      opacity: 1,

      transition: {
        delay: custom * 0.1,
        type: 'just',
        duration: 0.5,
      },
    }),
  };
  const textAnimationRight = {
    hidden: {
      // y: 0,
      // x: 20,
      opacity: 0,
    },
    visible: (custom) => ({
      x: 0,
      opacity: 1,

      transition: {
        delay: custom * 0.1,
        type: 'just',
        duration: 0.5,
      },
    }),
  };
  const textAnimationDescription = {
    hidden: {
      // y: 0,
      // x: 20,
      opacity: 0,
    },
    visible: (custom) => ({
      x: 0,
      opacity: 0.7,

      transition: {
        delay: custom * 0.1,
        type: 'just',
        duration: 0.5,
      },
    }),
  };
  return (
    <div className={styles.Student}>

    <h4 className={styles.title}>
    Student profile
    </h4>
    <p className={styles.mainText}>
    Contains information about students, which is pulled up from moodle and 1C
    </p>
    <div className={styles.buttonsBlock}>
    <Button
      appearance='primary'
      className={select == 'information' ? styles.buttonActive : styles.button}
      onClick={()=>setSelect('information')}
      >
      information
      </Button>

      <Button
      appearance='primary'
      className={select == 'grades' ? styles.buttonActive : styles.button}
      onClick={()=>setSelect('grades')}
      >
      grades
      </Button>

      <Button
      appearance='primary'
      className={select == 'predictions' ? styles.buttonActive : styles.button}
      onClick={()=>setSelect('predictions')}
      >
      predictions
      </Button>

      <Button
      appearance='primary'
      className={select == 'interests' ? styles.buttonActive : styles.button}
      onClick={()=>setSelect('interests')}
      >
      interests
      </Button>

      <Button
      appearance='primary'
      className={select == 'github' ? styles.buttonActive : styles.button}
      onClick={()=>setSelect('github')}
      >
      github
      </Button>
    </div>

    <section className={styles.selectedSection}>

    {select == 'information' && <motion.div className={styles.content}
    initial='hidden'
    whileInView='visible'
    viewport={{ amount: 0.1 }}>
      <div className={styles.leftSide}>
      <motion.h6 className={styles.titleContent} variants={textAnimation} custom={1}>
    Information
    </motion.h6>
    <motion.p className={styles.textContent} variants={textAnimationDescription} custom={1}>
    On this page, the user can see all the contact details of the student, as well as his photo, links to scientific literature
    </motion.p>
    <motion.div className={styles.buttons} variants={textAnimation} custom={2}>
    <a className={styles.link} 
    onClick={()=>location.href=`${request}`}>
    <Button
      appearance='primary'
      className={styles.buttonTry}
      // onClick={handleButton}
      >
      try it
      </Button>
      </a>
      <div className={styles.buttonContact} onClick={()=>setShowPopup(true)}>
      contact us
      <motion.img src={arrowShort} className={styles.arrowShort}/>
      </div>
      </motion.div>
    </div>
    <motion.img src={StudInf} className={styles.screenTwo}  variants={textAnimationRight} custom={1}/>
    </motion.div>}

    {select == 'grades' && <motion.div className={styles.content}
       initial='hidden'
       whileInView='visible'
       viewport={{ amount: 0.1 }}>
      <div className={styles.leftSide}>
      <motion.h6 className={styles.titleContent} variants={textAnimation} custom={1}>
    Grades
    </motion.h6>
    <motion.p className={styles.textContent} variants={textAnimationDescription} custom={1}>
    On this page, the instructor can see the grades in all disciplines and the student&apos;s GPA. We collect it from Moodle and Record book.
    </motion.p>
    <motion.div className={styles.buttons} variants={textAnimation} custom={2}>
    <a className={styles.link}  
    onClick={()=>location.href=`${request}`}>
    <Button
      appearance='primary'
      className={styles.buttonTry}
      // onClick={handleButton}
      >
      try it
      </Button>
      </a>
      <div className={styles.buttonContact} onClick={()=>setShowPopup(true)}>
      contact us
      <motion.img src={arrowShort} className={styles.arrowShort}/>
      </div>
      </motion.div>
    </div>
    <motion.img src={StudGrad} className={styles.screenTwo}  variants={textAnimationRight} custom={1}/>
    </motion.div>}

    {select == 'predictions' && <motion.div className={styles.content}
       initial='hidden'
       whileInView='visible'
       viewport={{ amount: 0.1 }}>
      <div className={styles.leftSide}>
      <motion.h6 className={styles.titleContent} variants={textAnimation} custom={1}>
    Predictions
    </motion.h6>
    <motion.p className={styles.textContent} variants={textAnimationDescription} custom={1}>
    On this page, the instructor can see the predictions of grades for the upcoming exams. This service was developed jointly with students.
    </motion.p>
    <motion.div className={styles.buttons} variants={textAnimation} custom={2}>
    <a className={styles.link}  
    onClick={()=>location.href=`${request}`}>
    <Button
      appearance='primary'
      className={styles.buttonTry}
      // onClick={handleButton}
      >
      try it
      </Button>
      </a>
      <div className={styles.buttonContact} onClick={()=>setShowPopup(true)}>
      contact us
      <motion.img src={arrowShort} className={styles.arrowShort}/>
      </div>
      </motion.div>
    </div>
    <motion.img src={StudPred}className={styles.screenTwo}  variants={textAnimationRight} custom={1}/>
    </motion.div>}

    {select == 'interests' && <motion.div className={styles.content}
       initial='hidden'
       whileInView='visible'
       viewport={{ amount: 0.1 }}>
      <div className={styles.leftSide}>
      <motion.h6 className={styles.titleContent} variants={textAnimation} custom={1}>
    Interests
    </motion.h6>
    <motion.p className={styles.textContent} variants={textAnimationDescription} custom={1}>
    On this page, the instructor can see the student&apos;s main hobbies, which he indicated upon admission.
    </motion.p>
    <motion.div className={styles.buttons} variants={textAnimation} custom={2}>
    <a className={styles.link} 
    onClick={()=>location.href=`${request}`}>
    <Button
      appearance='primary'
      className={styles.buttonTry}
      // onClick={handleButton}
      >
      try it
      </Button>
      </a>
      <div className={styles.buttonContact} onClick={()=>setShowPopup(true)}>
      contact us
      <motion.img src={arrowShort} className={styles.arrowShort}/>
      </div>
      </motion.div>
    </div>
    <motion.img src={StuInt} className={styles.screenTwo}  variants={textAnimationRight} custom={1}/>
    </motion.div>}

    {select == 'github' && <motion.div className={styles.content}
       initial='hidden'
       whileInView='visible'
       viewport={{ amount: 0.1 }}>
      <div className={styles.leftSide}>
      <motion.h6 className={styles.titleContent} variants={textAnimation} custom={1}>
    Github
    </motion.h6>
    <motion.p className={styles.textContent} variants={textAnimationDescription} custom={1}>
      If the student indicated his github upon admission, then on this page the instructor will be able to get acquainted with all the student&apos;s repositories.
      </motion.p>
      <motion.div className={styles.buttons} variants={textAnimation} custom={2}>
    <a className={styles.link}  
    onClick={()=>location.href=`${request}`}>
    <Button
      appearance='primary'
      className={styles.buttonTry}
      // onClick={handleButton}
      >
      try it
      </Button>
      </a>
      <div className={styles.buttonContact} onClick={()=>setShowPopup(true)}>
      contact us
      <motion.img src={arrowShort} className={styles.arrowShort}/>
      </div>
      </motion.div>
    </div>
    <motion.img src={StudGit}  className={styles.screenTwo}  variants={textAnimationRight} custom={1}/>
    </motion.div>}

    </section>
    {showPopup && <ContactUs setShowPopup={setShowPopup} showPopup={showPopup} setShowPopupAccepted={setShowPopupAccepted}/>}
    {showPopupAccepted && <Accepted setShowPopupAccepted={setShowPopupAccepted} showPopupAccepted={showPopupAccepted}/>}
       </div>
  )
}

export default Student