import React from 'react';
import styles from './CookiesPopup.module.scss';
import { useState, useEffect } from 'react';
import { setCookie, getCookie } from 'react-use-cookie';
function CookiesPopup() {
  const [showPopup, setShowPopup] = useState(false);
  const getPolicy = getCookie('policy');
  const pdfTwo =
    'https://innopolis.university/public/files/Consent_to_the_processing_of_PD_for_UI.pdf';
  useEffect(() => {
    if (!getPolicy) {
      setTimeout(() => {
        setShowPopup(true);
      }, '300');
    }
  }, []);

  useEffect(() => {
    if (getPolicy) {
      const interval = setInterval(() => {
        setCookie('policy', '');
        setShowPopup(true);
      }, 4200000);
      return () => clearInterval(interval);
    }
  }, [showPopup]);

  const acceptedHandler = () => {
    setShowPopup(false);
    setCookie('policy', 'accepted');
  };

  return (
    <div className={showPopup ? styles.CookiesPopup : styles.CookiesPopupHidden}>
      <p className={styles.text}>
        This website uses cookies to ensure you get best experience on our website. By continuing to
        use this site, you agree to this cookie usage. You can learn more in{' '}
        <a
          href={pdfTwo}
          target='_blank'
          className={styles.link}
          rel='noreferrer'
        >
          the privacy policy.
        </a>
      </p>
      <button
        className={styles.button}
        onClick={() => acceptedHandler()}
      >
        got it
      </button>
    </div>
  );
}

export default CookiesPopup;
