import React from 'react'
import styles from "./Eduwiki.module.scss"
import eduwiki from '../../../images/landing/eduwiki.svg'
import { motion } from 'framer-motion';
import { useMatchMedia } from '../../../hooks/useMatchMedia';
function Eduwiki() {
  const { isMobile } = useMatchMedia();
  const textAnimation = {
    hidden: {
      opacity: 0,
    },
    visible: (custom) => ({
      x: 0,
      opacity: 1,

      transition: {
        delay: custom * 0.2,
        type: 'just',
        duration: 0.5,
      },
    }),
  };
  const textAnimationDescription = {
    hidden: {
      opacity: 0,
    },
    visible: (custom) => ({
      x: 0,
      opacity: 0.7,

      transition: {
        delay: custom * 0.1,
        type: 'just',
        duration: 0.9,
      },
    }),
  };
  const mobile = {
    hidden: {
      opacity: 1,
    },
    visible: (custom) => ({
      x: 0,
      opacity: 1,

      transition: {
        delay: custom * 0.1,
        type: 'just',
        duration: 0.9,
      },
    }),
  };
  return (
    <div className={styles.Eduwiki}>
      <motion.div  className={styles.leftSide}
          initial='hidden'
          whileInView='visible'
          viewport={{ amount: 0.1 }}>
      <h5 className={styles.title}>
       Eduwiki Tools
      </h5>
      <motion.p className={styles.mainText} variants={!isMobile ? textAnimation : mobile} custom={1}>
      Download and upload syllabuses in a simple constructor
      </motion.p>
      <motion.p  className={styles.text} variants={!isMobile ? textAnimationDescription : mobile}  custom={2}>
      Difficult to update Markdown in EduWiki? No problem, work with docx format instead. Download any syllabus, update it and reupload.
      </motion.p>
      </motion.div>
      <div className={styles.rightSide}>
      <img src={eduwiki} className={styles.eduwikiImg}/>
      </div>
    </div>
  )
}

export default Eduwiki