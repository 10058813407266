import React, {useState} from 'react'
import { Button } from '../../../components/common/Button/Button'
import styles from './Instructor.module.scss'
import arrowShort from '../../../images/landing/arrowShort.svg'
import { motion } from 'framer-motion';
import chart from '../../../images/landing/chart.svg'
import userAdd from '../../../images/landing/userAdd.svg'
import feedbackTa from '../../../images/landing/feedbackTa.svg'
import feedbeckChart from '../../../images/landing/feedbeckChart.svg'
import checkMark from '../../../images/landing/checkMark.svg'
import { useMatchMedia } from '../../../hooks/useMatchMedia';
import InsInfo from '../../../images/landing/InsInfo.svg'
import InsBack from '../../../images/landing/InsBack.svg'
import InsCour from '../../../images/landing/InsCour.svg'
import ProjInst from '../../../images/landing/ProjInst.svg'
import PublInst from '../../../images/landing/PublInst.svg'
import ContactUs from '../ContactUs/ContactUs';
import Accepted from '../Accepted/Accepted';

function Instructor({request}) {
  const [select, setSelect] = useState('information')
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupAccepted, setShowPopupAccepted] = useState(false);
  const { isMobile } = useMatchMedia();
  const textAnimation = {
    hidden: {
      opacity: 0,
    },
    visible: (custom) => ({
      x: 0,
      opacity: 1,

      transition: {
        delay: custom * 0.2,
        type: 'just',
        duration: 0.5,
      },
    }),
  };
  const textAnimationRight = {
    hidden: {
      opacity: 0,
    },
    visible: (custom) => ({
      x: 0,
      opacity: 1,

      transition: {
        delay: custom * 0.1,
        type: 'just',
        duration: 0.5,
      },
    }),
  };
  const textAnimationDescription = {
    hidden: {
      opacity: 0,
    },
    visible: (custom) => ({
      x: 0,
      opacity: 0.7,

      transition: {
        delay: custom * 0.1,
        type: 'just',
        duration: 0.5,
      },
    }),
  };
  const Mobile = {
    hidden: {
      opacity: 1,
    },
    visible: (custom) => ({
      x: 0,
      opacity: 1,

      transition: {
        delay: custom * 0.1,
        type: 'just',
        duration: 0.9,
      },
    }),
  };
  return (
    <div className={styles.Instructor}>
    <h4 className={styles.title}>
    Instructor profile
    </h4>
    <p className={styles.mainText}>
    Contains information about professor, which he can fill out himself and personal feedback on all courses
    </p>
    <div className={styles.buttonsBlock}>
    <Button
      appearance='primary'
      className={select == 'information' ? styles.buttonActive : styles.button}
      onClick={()=>setSelect('information')}
      >
      information
      </Button>

      <Button
      appearance='primary'
      className={select == 'background' ? styles.buttonActive : styles.button}
      onClick={()=>setSelect('background')}
      >
      background
      </Button>

      <Button
      appearance='primary'
      className={select == 'publications' ? styles.buttonActive : styles.button}
      onClick={()=>setSelect('publications')}
      >
      publications
      </Button>

      <Button
      appearance='primary'
      className={select == 'courses' ? styles.buttonActive : styles.button}
      onClick={()=>setSelect('courses')}
      >
      courses
      </Button>

      <Button
      appearance='primary'
      className={select == 'projects' ? styles.buttonActive : styles.button}
      onClick={()=>setSelect('projects')}
      >
      projects
      </Button>
    </div>

    <section className={styles.selectedSection}>

    {select == 'information' && <motion.div className={styles.content}
    initial='hidden'
    whileInView='visible'
    viewport={{ amount: 0.1 }}>
      <div className={styles.leftSide}>
    <motion.h6 className={styles.titleContent} variants={textAnimation} custom={1}>
    Information
    </motion.h6>
    <motion.p className={styles.textContent} variants={textAnimationDescription} custom={1}>
    On this page, the user will be able to get acquainted with the contact information of the instructor, find out his office and working hours, the position within the university.
    </motion.p>
    <motion.div className={styles.buttons} variants={textAnimation} custom={2}>
      <a className={styles.link} 
       onClick={()=>location.href=`${request}`}>
    <Button
      appearance='primary'
      className={styles.buttonTry}
      >
      try it
      </Button>
      </a>
      <div className={styles.buttonContact} onClick={()=>setShowPopup(true)}>
      contact us
      <img src={arrowShort} className={styles.arrowShort}/>
      </div>
    </motion.div>
    </div>
    <motion.img src={InsInfo} className={styles.screenTwo}  variants={textAnimationRight} custom={2}/>
    </motion.div>}

    {select == 'background' && <motion.div className={styles.content}
        initial='hidden'
        whileInView='visible'
        viewport={{ amount: 0.1 }}>
      <div className={styles.leftSide}>
    <motion.h6 className={styles.titleContent} variants={textAnimation} custom={1}>
    Background
    </motion.h6>
    <motion.p className={styles.textContent} variants={textAnimationDescription} custom={1}>
    On this page, the user will be able to get acquainted with the education of the instructor, his previous jobs and see refresher courses.
    </motion.p>
    <motion.div className={styles.buttons} variants={textAnimation} custom={2}>
    <a className={styles.link}  
    onClick={()=>location.href=`${request}`}>
    <Button
      appearance='primary'
      className={styles.buttonTry}
      >
      try it
      </Button>
      </a>
      <div className={styles.buttonContact} onClick={()=>setShowPopup(true)}>
      contact us
      <img src={arrowShort} className={styles.arrowShort}/>
      </div>
      </motion.div>
    </div>
    <motion.img src={InsBack} className={styles.screenTwo}  variants={textAnimationRight} custom={2}/>
    </motion.div>}

    {select == 'publications' && <motion.div className={styles.content}
    initial='hidden'
    whileInView='visible'
    viewport={{ amount: 0.1 }}>
      <div className={styles.leftSide}>
    <motion.h6 className={styles.titleContent} variants={textAnimation} custom={1}>
    Publications
    </motion.h6>
    <motion.p className={styles.textContent} variants={textAnimationDescription} custom={1}>
    On this page, the user will be able to see all the publications of the instructor. <br/> in developing
    </motion.p>
    <motion.div className={styles.buttons} variants={textAnimation} custom={2}>
      <a className={styles.link} 
       onClick={()=>location.href=`${request}`}>
    <Button
      appearance='primary'
      className={styles.buttonTry}
      >
      try it
      </Button>
      </a>
      <div className={styles.buttonContact} onClick={()=>setShowPopup(true)}>
      contact us
      <img src={arrowShort} className={styles.arrowShort}/>
      </div>
    </motion.div>
    </div>
    <motion.img src={PublInst} className={styles.screenTwo}  variants={textAnimationRight} custom={2}/>
    </motion.div>}

    {select == 'courses' && <motion.div className={styles.content}
            initial='hidden'
            whileInView='visible'
            viewport={{ amount: 0.1 }}>
      <div className={styles.leftSide}>
      <motion.h6 className={styles.titleContent} variants={textAnimation} custom={1}>
    Courses
    </motion.h6>
    <motion.p className={styles.textContent} variants={textAnimationDescription} custom={1}>
    This page contains all the instructor&apos;s courses, as well as general and detailed feedback from students in all disciplines.
    </motion.p>
    <motion.div className={styles.buttons} variants={textAnimation} custom={2}>
    <a className={styles.link}  
    onClick={()=>location.href=`${request}`}>
    <Button
      appearance='primary'
      className={styles.buttonTry}
      >
      try it
      </Button>
      </a>
      <div className={styles.buttonContact} onClick={()=>setShowPopup(true)}>
      contact us
      <img src={arrowShort} className={styles.arrowShort}/>
      </div>
      </motion.div>
    </div>
    <motion.img src={InsCour} className={styles.screenTwo}  variants={textAnimationRight} custom={2}/>
    </motion.div>}

    {select == 'projects' && <motion.div className={styles.content}
    initial='hidden'
    whileInView='visible'
    viewport={{ amount: 0.1 }}>
      <div className={styles.leftSide}>
    <motion.h6 className={styles.titleContent} variants={textAnimation} custom={1}>
    Projects
    </motion.h6>
    <motion.p className={styles.textContent} variants={textAnimationDescription} custom={1}>
    If an instructor is interested in finding a student to collaborate with, this page will display projects that describe the task. <br/> in developing
    </motion.p>
    <motion.div className={styles.buttons} variants={textAnimation} custom={2}>
      <a className={styles.link} 
       onClick={()=>location.href=`${request}`}>
    <Button
      appearance='primary'
      className={styles.buttonTry}
      >
      try it
      </Button>
      </a>
      <div className={styles.buttonContact} onClick={()=>setShowPopup(true)}>
      contact us
      <img src={arrowShort} className={styles.arrowShort}/>
      </div>
    </motion.div>
    </div>
    <motion.img src={ProjInst} className={styles.screenTwo}  variants={textAnimationRight} custom={2}/>
    </motion.div>}
    </section>

      <h6 className={styles.feedbackTitle}>Personal feedback</h6>
      <section className={styles.blocksSection}>
      <div className={styles.block}  >
       <motion.div className={styles.wrapper}  initial='hidden'
        whileInView='visible'
        viewport={{ amount: 0.1 }}>
      <img src={chart} className={styles.blockPic}/>
      <h6 className={styles.blockTitle}>Summary feedback</h6>
      <motion.div className={styles.list} variants={textAnimation} custom={1}>
      <img src={checkMark} className={styles.checkMark}/>
      <p className={styles.listText}>feedback for all courses on one page</p>
      </motion.div>
      <motion.div className={styles.list} variants={textAnimation} custom={2}>
      <img src={checkMark} className={styles.checkMark}/>
      <p className={styles.listText}>quantitative and qualitative answers</p>
      </motion.div>
      <motion.div className={styles.list} variants={textAnimation} custom={3}>
      <img src={checkMark} className={styles.checkMark}/>
      <p className={styles.listText}>view the feedback for each TA</p>
      </motion.div>
      </motion.div>
      </div>
      

      <div className={styles.blockImg}>
      <img src={feedbeckChart} className={styles.feedbackImg}/>
      </div>

      {!isMobile && <div className={styles.blockImg}>
      <img src={feedbackTa} className={styles.feedbackImg}/>
      </div>}

      <motion.div className={styles.block}   initial='hidden'
        whileInView='visible'
        viewport={{ amount: 0.1 }}>
      <img src={userAdd} className={styles.blockPic}/>
      <h6 className={styles.blockTitle}>Personal feedback</h6>
      <motion.div className={styles.list} variants={!isMobile ? textAnimation : Mobile} custom={2}>
      <img src={checkMark} className={styles.checkMark}/>
      <p className={styles.listText}>all instructor courses feedback</p>
      </motion.div>
      <motion.div className={styles.list} variants={!isMobile ? textAnimation : Mobile} custom={3}>
      <img src={checkMark} className={styles.checkMark}/>
      <p className={styles.listText}>instructors team</p>
      </motion.div>
       </motion.div>
      {isMobile && <div className={styles.blockImg}>
      <img src={feedbackTa} className={styles.feedbackImg}/>
      </div>}
      </section>

    {showPopup && <ContactUs setShowPopup={setShowPopup} showPopup={showPopup} setShowPopupAccepted={setShowPopupAccepted}/>}
    {showPopupAccepted && <Accepted setShowPopupAccepted={setShowPopupAccepted} showPopupAccepted={showPopupAccepted}/>}
    </div>

  )
}

export default Instructor