import { useState, useLayoutEffect } from 'react';

const queries = [
  '(max-width: 767px)',
  '(min-width: 768px) and (max-width: 1279px)',
  '(min-width: 1280px)',
];

export function useMatchMedia() {
  const mediaQueryList = queries.map((query) => matchMedia(query));

  const getValue = () => mediaQueryList.map((mql) => mql.matches);

  const [value, setValue] = useState(getValue);

  useLayoutEffect(() => {
    const handler = () => setValue(getValue);

    mediaQueryList.forEach((mql) => mql.addEventListener('change', handler));

    return () => mediaQueryList.forEach((mql) => mql.removeEventListener('change', handler));
  });

  return ['isMobile', 'isTablet', 'isDesktop'].reduce(
    (acc, screen, index) => ({
      ...acc,
      [screen]: value[index],
    }),
    {},
  );
}
