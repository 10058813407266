import React from 'react';
import styles from './Header.module.css';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
function HeaderNavStudent({ miniBarOpen, setMiniBarOpen }) {
  let location = useLocation();
  const permissions = useSelector((state) => state.token.role);
  let idLocation = location.pathname.split('').slice(9).join('').split('/')[0];

  return (
    <nav className={styles.HeaderNavStudent}>
      <NavLink
        to={`/student/${idLocation}/information`}
        className={styles.button}
      >
        <button
          className={styles.button}
          onClick={() => setMiniBarOpen(!miniBarOpen)}
        >
          <p className={styles.pActive}>information</p>
        </button>
      </NavLink>
      {!permissions.includes(process.env.REACT_APP_PERMISSION_SV) && (
        <NavLink
          to={`/student/${idLocation}/grades`}
          className={styles.button}
        >
          <button
            className={styles.button}
            onClick={() => setMiniBarOpen(!miniBarOpen)}
          >
            <p className={styles.pActive}>grades</p>
          </button>
        </NavLink>
      )}
      {!permissions.includes(process.env.REACT_APP_PERMISSION_SV) && (
        <NavLink
          to={`/student/${idLocation}/predicted`}
          className={styles.button}
        >
          <button
            className={styles.button}
            onClick={() => setMiniBarOpen(!miniBarOpen)}
          >
            <p className={styles.pActive}>prediction</p>
          </button>
        </NavLink>
      )}
      {!permissions.includes(process.env.REACT_APP_PERMISSION_SV) && (
        <NavLink
          to={`/student/${idLocation}/interests`}
          className={styles.button}
        >
          <button
            className={styles.button}
            onClick={() => setMiniBarOpen(!miniBarOpen)}
          >
            <p className={styles.pActive}>interests</p>
          </button>
        </NavLink>
      )}
      {!permissions.includes(process.env.REACT_APP_PERMISSION_SV) && (
        <NavLink
          to={`/student/${idLocation}/github`}
          className={styles.button}
        >
          <button
            className={styles.button}
            onClick={() => setMiniBarOpen(!miniBarOpen)}
          >
            <p className={styles.pActive}>github</p>
          </button>
        </NavLink>
      )}
      {permissions.includes(process.env.REACT_APP_PERMISSION_AD) && (
        <NavLink
          to={`/student/${idLocation}/notes`}
          className={styles.button}
        >
          <button
            className={styles.button}
            onClick={() => setMiniBarOpen(!miniBarOpen)}
          >
            <p className={styles.pActive}>notes</p>
          </button>
        </NavLink>
      )}
    </nav>
  );
}

export default HeaderNavStudent;
