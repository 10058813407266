import { createSlice } from '@reduxjs/toolkit';

export const instructorSlice = createSlice({
  name: 'instructor',
  initialState: {
    courseTaken: '',
    feedbackGiven: '',
    instructorId: '',
    tokenPlusdata: '',
    details: [],
    getAccess: '',
    name: '',
    loadingData: false,
    feedbackData: [],
    feedbackDataStaff: [],
  },
  reducers: {
    getStudentsCount(state, action) {
      state.courseTaken = action.payload;
    },
    getFeedback(state, action) {
      state.feedbackGiven = action.payload;
    },
    getinstructorId(state, action) {
      state.instructorId = action.payload;
    },

    gettokenPlusdata(state, action) {
      state.tokenPlusdata = action.payload;
    },
    getdetails(state, action) {
      state.details = action.payload;
    },
    getgetAccess(state, action) {
      state.getAccess = action.payload;
    },
    getTAname(state, action) {
      state.name = action.payload;
    },
    getLoadingData(state, action) {
      state.loadingData = action.payload;
    },
    getfeedbackData(state, action) {
      if (!state.feedbackDataStaff.includes(action.payload[0])) {
        state.feedbackDataStaff = [...state.feedbackDataStaff, action.payload[0]];
        state.feedbackData = Number(state.feedbackData) + Number(action.payload[1]);
      }
    },
  },
});

export const {
  getStudentsCount,
  getFeedback,
  getdetails,
  getgetAccess,
  getinstructorId,
  gettokenPlusdata,
  getTAname,
  getLoadingData,
  getfeedbackData,
} = instructorSlice.actions;

export default instructorSlice.reducer;
