import React, { useState } from 'react'
import styles from './Slider.module.scss'
import dataSlider from './DataSlider';
// import BtnSlider from './BtnSlider';
import { useMatchMedia } from '../../../hooks/useMatchMedia';
function Slider() {
  const [slideIndex, setSlideIndex] = useState(1);
  const [slideIndexSecond, setSlideIndexSecond] = useState(2);
  const { isMobile } = useMatchMedia();
  // useEffect(() => {
  //   const play = setInterval(() => {
  //     if (slideIndex !== dataSlider.length) {
  //       setSlideIndex(slideIndex + 1);
  //     } else if (slideIndex === dataSlider.length) {
  //       setSlideIndex(1);
  //     }
  //     if (slideIndexSecond !== dataSlider.length) {
  //       setSlideIndexSecond(slideIndexSecond + 1);
  //     } else if (slideIndexSecond === dataSlider.length) {
  //       setSlideIndexSecond(1);
  //     }
  //   }, 15000);
  //   return () => clearInterval(play);
  // }, [slideIndex, slideIndexSecond]);

  // const nextSlide = () => {
  //   if (slideIndex !== dataSlider.length) {
  //     setSlideIndex(slideIndex + 1);
  //   } else if (slideIndex === dataSlider.length) {
  //     setSlideIndex(1);
  //   }

  //   if (slideIndexSecond !== dataSlider.length) {
  //     setSlideIndexSecond(slideIndexSecond + 1);
  //   } else if (slideIndexSecond === dataSlider.length) {
  //     setSlideIndexSecond(1);
  //   }
  // };
  // const prevSlide = () => {
  //   if (slideIndex !== 1) {
  //     setSlideIndex(slideIndex - 1);
  //   } else if (slideIndex === 1) {
  //     setSlideIndex(dataSlider.length);
  //   }

  //   if (slideIndexSecond !== 1) {
  //     setSlideIndexSecond(slideIndexSecond - 1);
  //   } else if (slideIndexSecond === 1) {
  //     setSlideIndexSecond(dataSlider.length);
  //   }
  // };

  const moveDot = (index) => {
    setSlideIndex(index)
    if (slideIndexSecond !== dataSlider.length) {
    setSlideIndexSecond(index + 1)
  } else if (slideIndexSecond === dataSlider.length) {
        setSlideIndexSecond(1);
      }
  };
  return (
    <div className={styles.Slider}>
      <p className={styles.text}>Here is what<span className={styles.colorText}> our users say:</span></p>

      <section
        className={styles.sliderSection}
      >
        {dataSlider.map((obj, index) => {
          return (
            <div
              key={obj.id}
              className={slideIndex === index + 1 ? styles.activeAnim : styles.slide}
            >
              <img
                className={styles.img}
                src={process.env.PUBLIC_URL + `/slider/${index + 1}.jpg`}
              />
              <h4 className={styles.name}>{obj.name}</h4>
              <h5 className={styles.job}>{obj.job}</h5>
              <p className={styles.description}>{obj.description}</p>
            </div>
          );
        })}

        {!isMobile && dataSlider.map((obj, index) => {
          return (
            <div
              key={obj.id}
              className={slideIndexSecond === index + 1 ? styles.activeAnim : styles.slide}
            >
              <img
                className={styles.img}
                src={process.env.PUBLIC_URL + `/slider/${index + 1}.jpg`}
              />
              <h4 className={styles.name}>{obj.name}</h4>
              <h5 className={styles.job}>{obj.job}</h5>
              <p className={styles.description}>{obj.description}</p>
            </div>
          );
        })}
        {/* <BtnSlider
          moveSlide={nextSlide}
          direction={'next'}
        />
        <BtnSlider
          moveSlide={prevSlide}
          direction={'prev'}
        /> */}

          {isMobile &&  <div className={styles.containerDots}>
          {Array.from({ length: 2 }).map((item, index) => (
            <div
              key={Math.random()}
              onClick={() => moveDot(index + 1)}
              className={slideIndex === index + 1 ? styles.dotActive : styles.dot}
            ></div>
          ))}
        </div>}
      </section>



    </div>
  )
}

export default Slider