const dataSlider = [
  {
    id: 1,
    name: 'Gaponov Igor',
    job: 'Professor at Innopolis University',
    description: `Интерфейс "Digital Profile" для меня намного интуитивнее и проще, чем интерфейс Moodle. На мой взгляд, использование подобных платформ поможет повысить качество и глубину взаимодействия студентов и преподавателей.`,
  },
  {
    id: 2,
    name: 'Mazzara Manuel',
    job: 'Professor at Innopolis University',
    description:
      "The system greatly simplifies the instructor's work providing a simple interface and prompt access to the essential data used in the educational process. It is a notable example of an excellent product.",
  },
];

export default dataSlider;
