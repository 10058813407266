import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getGenerator = createAsyncThunk(
  'getGenerator',
  async function (dataGenerator, { dispatch }) {
    let myHeaders = new Headers();
    myHeaders.append('authorization', `Bearer ${dataGenerator.getAccess}`);
    let formdata = new FormData();
    formdata.append('degree', dataGenerator.degree);
    formdata.append('course_type', dataGenerator.courseType);
    formdata.append('document', dataGenerator.formData);
    formdata.append('course_name', dataGenerator.course_name);
    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };
    fetch(`${process.env.REACT_APP_BASE_URL}/generator/api/eduwiki/upload/`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let generatorResult = JSON.parse(result);
        dispatch(getDataGenerator(generatorResult));
        dispatch(getStatus(false));
      })
      .catch((error) => {
        console.log('error', error);
      });
  },
);

export const getRPDID = createAsyncThunk('getRPDID', async function (dataGenerator, { dispatch }) {
  let myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${dataGenerator.getAccess}`);
  let requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  fetch(
    `${process.env.REACT_APP_BASE_URL}/generator/api/rpd/find_study_plans/?course_name=${dataGenerator.discipline}`,
    requestOptions,
  )
    .then((response) => response.text())
    .then((result) => {
      let generatorResult = JSON.parse(result);
      dispatch(getCourse(generatorResult));
    })
    .catch((error) => {
      console.log('error', error);
    });
});

export const postRPD = createAsyncThunk('postRPD', async function (dataGenerator, { dispatch }) {
  let myHeaders = new Headers();
  myHeaders.append('authorization', `Bearer ${dataGenerator.getAccess}`);
  let formdata = new FormData();
  formdata.append('course_id', dataGenerator.course_id);
  formdata.append('translate', dataGenerator.translate);
  formdata.append('plans_id', dataGenerator.plans_id);
  formdata.append('document', dataGenerator.document);
  let requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow',
  };
  fetch(`${process.env.REACT_APP_BASE_URL}/generator/api/rpd/generate/`, requestOptions)
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      if (blob.type === 'application/json' || blob.type === 'text/html') {
        dispatch(getError('the selected file is not suitable'));
        dispatch(getStatus(false));
      } else {
        dispatch(getError('application/x-zip-compressed'));
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'RPD.zip');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        dispatch(getStatus(false));
      }
    })
    .catch((error) => {
      console.log('error', error);
    });
});

export const downloadSyllabus = createAsyncThunk(
  'Download',
  async function (dataGenerator, { dispatch }) {
    let myHeaders = new Headers();
    myHeaders.append('authorization', `Bearer ${dataGenerator.getAccess}`);
    let requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    fetch(
      `${process.env.REACT_APP_BASE_URL}/generator/api/eduwiki/download/?page=${dataGenerator.page}`,
      requestOptions,
    )
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        if (blob.type === 'application/json' || blob.type === 'text/html') {
          dispatch(getError('the selected file is not suitable'));
          dispatch(getStatus(false));
        } else {
          dispatch(getError('application/x-zip-compressed'));
          const href = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `Syllabus.docx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          dispatch(getStatus(false));
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  },
);

export const getInstructorPositions = createAsyncThunk(
  'DownloadPositions',
  async function (token, { dispatch }) {
    let myHeaders = new Headers();
    myHeaders.append('authorization', `Bearer ${token}`);
    let requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    fetch(`${process.env.REACT_APP_BASE_URL}/api/moodle_users/instructor_positions`, requestOptions)
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        var blob = new Blob([decodeURIComponent('%ef%bb%bf') /*prepend bom*/, text], {
          type: 'text/csv;charset=utf-8',
        });
        dispatch(getError('application/x-zip-compressed'));
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `InstructorPositions.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        dispatch(getStatus(false));
      })
      .catch((error) => {
        console.log('error', error);
      });
  },
);

export const generatorSlice = createSlice({
  name: 'generator',
  initialState: {
    data: [],
    course: [],
    status: false,
    error: '',
  },
  reducers: {
    getDataGenerator(state, action) {
      if(action.payload.Status === 'Fail' && action.payload.Message.includes('post an error')){
        state.data = action.payload.Message
      } else if (action.payload.Status === 'Fail') {
        state.data = 'the selected file is not suitable';
      } else if (action.payload.Status === 'Success') {
        state.data = action.payload.Message;
      } else {
        state.data = '';
      }
    },
    getCourse(state, action) {
      state.course = action.payload;
    },
    getStatus(state, action) {
      state.status = action.payload;
    },
    getError(state, action) {
      if (action.payload === 'the selected file is not suitable') {
        state.error = 'the selected file is not suitable';
      } else if (action.payload === 'application/x-zip-compressed') {
        state.error = 'RPD has been successfully formed';
      } else {
        state.error = '';
      }
    },
    getFile(state, action) {
      const href = window.URL.createObjectURL(action.payload);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'RPD.zip');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
});

export const { getDataGenerator, getCourse, getStatus, getError, getFile } = generatorSlice.actions;

export default generatorSlice.reducer;
