import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setCookie } from 'react-use-cookie';

export const getNewToken = createAsyncThunk(
  'getNewToken',
  async function (codePlusLocation, { dispatch }) {
    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    let urlencoded = new URLSearchParams();

    urlencoded.append('code', codePlusLocation.codeData);
    if (codePlusLocation.lacation == '/') {
      urlencoded.append('redirect_uri', `${process.env.REACT_APP_BASE_URL}`);
    } else {
      urlencoded.append(
        'redirect_uri',
        `${process.env.REACT_APP_BASE_URL}${codePlusLocation.lacation}`,
      );
    }
    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow',
    };
    fetch(`${process.env.REACT_APP_BASE_URL}/api/auth/auth_with_code/`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let _token = JSON.parse(result);
        if (_token?.errors) {
          dispatch(isError(true));
        }
        if (_token.error === 'invalid_grant') {
          dispatch(getPermissionToken(false));
        }
        setCookie('access_token', _token.access_token, {
          days: 0.2,
          SameSite: 'Lax',
          Secure: true,
        });
        setCookie('refresh_token', _token.refresh_token, {
          days: 0.6,
          SameSite: 'Lax',
          Secure: true,
        });
        dispatch(getFamily(_token.family_name));
        dispatch(getName(_token.given_name));
        dispatch(getRole(_token.user_auth_groups));
        dispatch(getEmail(_token.email));
        dispatch(getMoodleId(_token.moodle_id));
        dispatch(isError(false));
      })
      .catch((error) => {
        dispatch(isError(true));
        console.log('error', error);
        setCookie('access_token', '');
        setCookie('refresh_token', '');
      });
  },
);

export const checkToken = createAsyncThunk('checkToken', async function (getRefresh, { dispatch }) {
  let myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
  let urlencoded = new URLSearchParams();
  urlencoded.append('refresh_token', getRefresh);
  let requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow',
  };
  fetch(
    `${process.env.REACT_APP_BASE_URL}/api/auth/update_token_with_refresh_token/`,
    requestOptions,
  )
    .then((response) => response.text())
    .then((result) => {
      let _token = JSON.parse(result);
      if (_token?.errors) {
        dispatch(isError(true));
      }
      if (_token.error === 'invalid_grant') {
        dispatch(getPermissionToken(false));
      }
      setCookie('access_token', _token.access_token, {
        days: 0.2,
        SameSite: 'Lax',
        Secure: true,
      });
      dispatch(getFamily(_token.family_name));
      dispatch(getName(_token.given_name));
      dispatch(getRole(_token.user_auth_groups));
      dispatch(getEmail(_token.email));
      dispatch(getMoodleId(_token.moodle_id));
      dispatch(isError(false));
    })
    .catch((error) => {
      console.log('catch');
      dispatch(isError(true));
      console.log('error', error);
      setCookie('access_token', '');
      setCookie('refresh_token', '');
    });
});

export const updateData = createAsyncThunk('checkToken', async function (getRefresh, { dispatch }) {
  let myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
  let urlencoded = new URLSearchParams();
  urlencoded.append('refresh_token', getRefresh);
  let requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow',
  };
  fetch(
    `${process.env.REACT_APP_BASE_URL}/api/auth/update_token_with_refresh_token/`,
    requestOptions,
  )
    .then((response) => response.text())
    .then((result) => {
      let _token = JSON.parse(result);
      if (_token?.errors) {
        dispatch(isError(true));
      }
      if (_token.error === 'invalid_grant') {
        dispatch(getPermissionToken(false));
      }
      dispatch(getFamily(_token.family_name));
      dispatch(getName(_token.given_name));
      dispatch(getRole(_token.user_auth_groups));
      dispatch(getEmail(_token.email));
      dispatch(getMoodleId(_token.moodle_id));
      dispatch(isError(false));
    })
    .catch((error) => {
      dispatch(isError(true));
      console.log('error', error);
      setCookie('access_token', '');
      setCookie('refresh_token', '');
    });
});

export const tokenSlice = createSlice({
  name: 'token',
  initialState: {
    name: '',
    family: '',
    email: '',
    role: '',
    permissionToken: '',
    moodleId: '',
    isError: false,
  },
  reducers: {
    getName(state, action) {
      state.name = action.payload;
    },
    getFamily(state, action) {
      state.family = action.payload;
    },
    getRole(state, action) {
      state.role = action.payload;
    },
    getEmail(state, action) {
      state.email = action.payload;
    },
    getPermissionToken(state, action) {
      state.permissionToken = action.payload;
    },
    getMoodleId(state, action) {
      state.moodleId = action.payload;
    },
    isError(state, action) {
      state.isError = action.payload;
    },
  },
});

export const { getName, getFamily, getEmail, getRole, getPermissionToken, getMoodleId, isError } =
  tokenSlice.actions;

export default tokenSlice.reducer;
