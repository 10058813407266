import React from 'react';
import styles from './LegalInformation.module.scss';
import plusClose from '../../../images/plusClose.svg';
import pdf from '../../../images/pdf.svg';

function LegalInformation({ setShowPopup, showPopup }) {
  const pdfOne =
    'https://innopolis.university/public/files/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D0%B5%20%D0%BD%D0%B0%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D1%83%20%D0%9F%D0%94%D0%BD%20%D0%B4%D0%BB%D1%8F%20%D0%A3%D0%98.pdf';
  const pdfTwo =
    'https://innopolis.university/public/files/Consent_to_the_processing_of_PD_for_UI.pdf';
  return (
    <div
      className={showPopup ? styles.LegalInformation : styles.LegalInformationHidden}
      onClick={() => setShowPopup(false)}
    >
      <div
        className={showPopup ? styles.popup : styles.popupHidden}
        onClick={(e) => e.stopPropagation()}
      >
        <img
          src={plusClose}
          className={styles.img}
          onClick={() => setShowPopup(false)}
        />
        <h4 className={styles.title}>Legal information</h4>

        <a
          href={pdfOne}
          target='_blank'
          className={styles.linkTop}
          rel='noreferrer'
        >
          <img
            src={pdf}
            className={styles.pdf}
          />
          <p className={styles.text}>
            Согласие на обработку персональных данных от Университета Иннополис
          </p>
        </a>

        <a
          href={pdfTwo}
          target='_blank'
          className={styles.linkButtom}
          rel='noreferrer'
        >
          <img
            src={pdf}
            className={styles.pdf}
          />
          <p className={styles.text}>Consent to the processing of PD for UI</p>
        </a>
      </div>
    </div>
  );
}

export default LegalInformation;
