import React from 'react'
import styles from './HeaderLanding.module.scss';
import logoDigital from '../../../images/лого хедер 1920.svg';
function HeaderLanding({request}) {
  return (
    <div className={styles.Header}>
      <div className={styles.interualSection}>
        <img
        src={logoDigital}
        alt='DIGITAL PROFILE'
        className={styles.logoDigital}
    />   
    <a className={styles.link} 
    onClick={()=>location.href=`${request}`}>
    <p className={styles.linkText}>sign in</p>
    </a>
    </div>
    </div>
  )
}

export default HeaderLanding