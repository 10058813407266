import { Api } from './Api';

export const EduWiki = Api.injectEndpoints({
  endpoints: (build) => ({
    getEduwiki: build.query({
      query: (search) => ({
        url: `https://eduwiki.innopolis.university/api.php?action=query&format=json&list=prefixsearch&pssearch=${search}&origin=*`,
        methods: 'GET',
      }),
    }),
  }),
});
export const { useGetEduwikiQuery } = EduWiki;
