import React from 'react';
import styles from './Header.module.css';
import { NavLink } from 'react-router-dom';
function HeaderNavGenerator({ miniBarOpen, setMiniBarOpen }) {
  return (
    <nav className={styles.HeaderNavGenerator}>
      <NavLink
        to={`/generate/rpd`}
        className={styles.button}
      >
        <button
          className={styles.button}
          onClick={() => setMiniBarOpen(!miniBarOpen)}
        >
          <p className={styles.pActive}>rpd generator</p>
        </button>
      </NavLink>
      <NavLink
        to={`/generate/syllabus`}
        className={styles.button}
      >
        <button
          className={styles.button}
          onClick={() => setMiniBarOpen(!miniBarOpen)}
        >
          <p className={styles.pActive}>upload syllabus</p>
        </button>
      </NavLink>

      <NavLink
        to={`/generate/download_syllabus`}
        className={styles.button}
      >
        <button
          className={styles.button}
          onClick={() => setMiniBarOpen(!miniBarOpen)}
        >
          <p className={styles.pActive}>download syllabus</p>
        </button>
      </NavLink>
    </nav>
  );
}

export default HeaderNavGenerator;
