import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
export const usePermission = (...role) =>{
    const permissions = useSelector((state) => state.token.role);
    const [permissionAccess, setPermissionAccess] = useState(false);
    useEffect(() => {
        for (let i=0; i<role.length; i++){
            if (permissions.includes(role[i])) {
                return setPermissionAccess(true);
            }
        }
      }, [permissions]);
      return permissionAccess
}