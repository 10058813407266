import React, { forwardRef, memo } from 'react';

/**
 * This is an internal helper component for rendering icons
 * @param { string } className - Class name passed to the component
 * @param { svgPaths16 } data - The svg icon
 * @param { number } size - Size of the icon, in pixels
 * @param { string } alt - Description string
 */
export const Icon = memo(
  forwardRef(function Icon({ className, size = 16, data, alt = 'icon', ...restStyles }, ref) {
    return (
      <img
        ref={ref}
        className={[className].join(' ')}
        width={size}
        height={size}
        src={data}
        alt={alt}
        style={{
          ...restStyles,
        }}
      />
    );
  }),
);
