import React, {useEffect} from 'react';
import styles from './Accepted.module.scss';
function Accepted({ setShowPopupAccepted, showPopupAccepted }) {

const close = () =>{
    setShowPopupAccepted(false)
}
useEffect(()=>{
  setTimeout(() => {
    setShowPopupAccepted(false)
  }, '6000');
},[])

  return (
<div className={showPopupAccepted ? styles.popup : styles.popupHidden} onClick={close}>
<p className={styles.text}>Your request has been received, we will contact you shortly</p>
</div>
  );
}

export default Accepted;
