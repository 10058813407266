import { configureStore } from '@reduxjs/toolkit';
import { Api } from './Api';
import tokenReducer from './tokenSlice';
import studentsReducer from './studentSlise';
import instructorReducer from './instructorSlice';
import generatorReducer from './generatorSlice';

export const store = configureStore({
  reducer: {
    [Api.reducerPath]: Api.reducer,
    token: tokenReducer,
    students: studentsReducer,
    generator: generatorReducer,
    instructor: instructorReducer,
  },
  middleware: (getDefaultMiddlware) => getDefaultMiddlware().concat(Api.middleware),
});
