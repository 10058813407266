import React, { useState} from 'react';
import styles from './ContactUs.module.scss';
import plusClose from '../../../images/plusClose.svg';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { ContactData } from '../../../redux/studentSlise';
function ContactUs({ setShowPopup, showPopup, setShowPopupAccepted }) {
  const dispatch = useDispatch();
  const contactData = useSelector((state) => state.students.contacts);
  const [name, setName] = useState(contactData?.name)
  const [telegram, setTelegram] = useState(contactData?.telegram)
  const [description, setDescription] = useState(contactData?.description)
      const handleForm = () =>{
        axios.get(`https://api.telegram.org/${process.env.REACT_APP_BOT_KIRILL}&text= name - ${name} , telegram  -${telegram}, Description - ${description},`)
        setShowPopup(false)
        setShowPopupAccepted(true)
        dispatch(ContactData({}))
      }
  const close = () =>{
    dispatch(ContactData({
      name: name,
      telegram: telegram,
      description: description
    }))
    setShowPopup(false)
  }
  return (
    <div
      className={showPopup ? styles.ContactUs : styles.ContactUsHidden}
      onClick={close}
    >
      <div
        className={showPopup ? styles.popup : styles.popupHidden}
        onClick={(e) => e.stopPropagation()}
      >
        <img
          src={plusClose}
          className={styles.img}
          onClick={close}
        />
        <h4 className={styles.title}>Contact us</h4>
        <div className={styles.mainDiv}>

      <p className={styles.text}>Your name</p>
      <input className={styles.input} placeholder='enter your name' maxLength={100} minLength={2} value={name} onChange={(e)=>setName(e.target.value)}/>
     
      <p className={styles.text}>Your telegram</p>
      <input className={styles.input} placeholder='enter your telegram username' maxLength={100} minLength={2} value={telegram} onChange={(e)=>setTelegram(e.target.value)}/>

      <p className={styles.text}>Description of the appeal</p>
      <textarea className={styles.inputArea} placeholder='enter your description' maxLength={1100} minLength={2} value={description} onChange={(e)=>setDescription(e.target.value)}/>
          
      </div>
      <button className={styles.button}  disabled={!name || !telegram || !description} onClick={handleForm}>
        <p>send</p></button>
      </div>
    </div>
  );
}

export default ContactUs;
