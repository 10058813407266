import styles from "./ModalOverlay.module.scss";
import PropTypes from 'prop-types';
import React from 'react'
function ModalOverlay ({ setSelectorShow }) {
    ModalOverlay.propTypes = {
        setOpenModal: PropTypes.func.isRequired
    }
    return (
        <div className={styles.overlay} onClick={()=>setSelectorShow(false)}></div>
    )
}


export default ModalOverlay;