const ruKeyCodes = {
  81: 'й',
  87: 'ц',
  69: 'у',
  82: 'к',
  84: 'е',
  89: 'н',
  85: 'г',
  73: 'ш',
  79: 'щ',
  80: 'з',
  219: 'х',
  221: 'ъ',
  65: 'ф',
  83: 'ы',
  68: 'в',
  70: 'а',
  71: 'п',
  72: 'р',
  74: 'о',
  75: 'л',
  76: 'д',
  186: 'ж',
  222: 'э',
  90: 'я',
  88: 'ч',
  67: 'с',
  86: 'м',
  66: 'и',
  78: 'т',
  77: 'ь',
  188: 'б',
  190: 'ю',
  192: 'ё',
};

const enKeyCodes = {
  81: 'q Q',
  87: 'w W',
  69: 'e E',
  82: 'r R',
  84: 't T',
  89: 'y Y',
  85: 'u U',
  73: 'i I',
  79: 'o O',
  80: 'p P',
  65: 'a A',
  83: 's S',
  68: 'd D',
  70: 'f F',
  71: 'g G',
  72: 'h H',
  74: 'j J',
  75: 'k K',
  76: 'l L',
  90: 'z Z',
  88: 'x X',
  67: 'c C',
  86: 'v V',
  66: 'b B',
  78: 'n N',
  77: 'm M',
};

export function useSearchTranslate(filter) {
  let found = /^([a-z]+|\d+)$/i;
  let number = /[0-9]/;
  if (!found.test(filter)) {
    return;
  }
  if (number.test(filter)) {
    return;
  }
  const filterTranslate = filter.split('').map((item) => {
    let translate = [];
    let keyChar = [];
    for (let char in enKeyCodes) {
      if (enKeyCodes[char].includes(item)) {
        keyChar.push(char);
        translate = ruKeyCodes[keyChar];
      }
    }
    let filterTranslate = translate;
    return filterTranslate;
  });
  let result = filterTranslate.join('');
  return result;
}
