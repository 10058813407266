import React, { forwardRef, memo } from 'react';
import Box, { splitBoxProps } from 'ui-box';
import style from './Button.module.scss';
import { Icon } from '../Icon/Icon';

export const internalStyles = {
  position: 'relative',
  fontWeight: 500,
  display: 'inline-flex',
  alignItems: 'center',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  textDecoration: 'none',
  verticalAlign: 'middle',
  border: 'none',
  outline: 'none',
  userSelect: 'none',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  WebkitFontSmoothing: 'antialiased',
  WebkitAppearance: 'none',
  MozAppearance: 'none',
  '&::-moz-focus-inner ': {
    border: 0,
  },
};

export const pseudoSelectors = {
  _active:
    '&:not([disabled]):active, &:not([disabled])[aria-expanded="true"], &:not([disabled])[data-active]',
  _disabled: '&[disabled]',
  _focus: '&:not([disabled]):focus',
  _focusAndActive:
    '&:not([disabled]):focus:active, &:not([disabled])[aria-expanded="true"]:focus, &:not([disabled])[data-active]:focus',
  _hover: '&:not([disabled]):hover',
};

export const getIconSizeForButton = (size) => {
  if (size === 's') return 16;
  if (size === 'm') return 20;
  return 26;
};

const ButtonIcon = memo(function ButtonIcon({ edge, icon, size, spacing }) {
  if (!icon) return null;

  const relativeSpace = typeof spacing === 'number' ? spacing : size;
  const edgeMargin = -Math.round(relativeSpace * 0.25);
  const innerMargin = Math.round(size * 0.4);
  const marginLeft = edge === 'start' ? edgeMargin : innerMargin;
  const marginRight = edge === 'end' ? edgeMargin : innerMargin;

  return (
    <Icon
      data={icon}
      size={size}
      marginLeft={marginLeft}
      marginRight={marginRight}
    />
  );
});

/**
 * This is a dummy component of Button with ability to add icon
 * @param { string } className - Class name passed to the component
 * @param { 'default' | 'primary' | 'secondary'  } appearance - The appearance of the button
 * @param { 's' | 'm' | 'l' } size - The size of the button
 * @param { bool } isActive - Forcefully set the active state of a button
 * @param { bool } disabled - When true, the button is disabled
 * @param { svgPaths16 } iconBefore - Sets an icon before the text
 * @param { svgPaths16 } iconAfter - Sets an icon after the text
 */
export const Button = memo(
  forwardRef(function Button(props, ref) {
    const {
      appearance = 'default',
      children,
      className,
      disabled,
      iconAfter,
      iconBefore,
      isActive = false,
      isLoading,
      size = 'm',
      onClick,
      ...restProps
    } = props;

    const buttonClassName = [
      className,
      style.Button,
      style[disabled ? 'disabled' : appearance],
      style[size],
    ].join(' ');

    const iconSize = getIconSizeForButton(size);

    const { className: themedClassName, ...boxProps } = splitBoxProps(
      { appearance, size },
      pseudoSelectors,
      internalStyles,
    );

    return (
      <Box
        is='button'
        ref={ref}
        className={[buttonClassName, themedClassName, className].join(' ')}
        data-active={isActive || undefined}
        {...boxProps}
        {...restProps}
        onClick={onClick}
        disabled={disabled || isLoading}
      >
        <ButtonIcon
          icon={iconBefore}
          size={iconSize}
          spacing={restProps.paddingLeft}
          edge='start'
        />
        {children}
        <ButtonIcon
          icon={iconAfter}
          size={iconSize}
          spacing={restProps.paddingRight}
          edge='end'
        />
      </Box>
    );
  }),
);
